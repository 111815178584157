import { useDispatch, useSelector } from "react-redux";
import { clear } from "../redux/notificationSlice";

function Notification() {
    const notification = useSelector((state) => state.notification.value);
    const dispatch = useDispatch();

    return (
        notification &&
        <div className="toast-container bottom-0 end-0 p-3" onClick={() => dispatch(clear())} >
            <div className={`toast align-items-center text-bg-${notification.state} border-0 show m-2`} role="alert" aria-live="assertive" aria-atomic="true">
                <div className="d-flex">
                    <div className="toast-body">
                        {notification.message}
                    </div>
                    <button type="button" className={`btn-close btn-close${notification.state === 'warning' ? '' : '-white'} me-2 m-auto`} data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
            </div>
        </div>
    )
}

export default Notification;