import Calendar from 'react-calendar';
import './WrappedCalendar.css'

function WrappedCalendar(props) {
    let minDate = new Date();
    minDate.setHours(0, 0, 0, 0);

    const tileClassName = ({ date, view }) => {
        if (view === 'year') {
            if (props.selectedDates
                .some(selectedDate => selectedDate.getYear() === date.getYear()
                    && selectedDate.getMonth() === date.getMonth())) {
                return 'selected-date';
            }
        }

        if (view === 'month') {
            if (props.selectedDates.filter(selectedDate => selectedDate.getTime() === date.getTime()).length === 1) {
                return 'bg-success text-white';
            }

            if (props.availableDates.filter(selectedDate => selectedDate.getTime() === date.getTime()).length === 1) {
                return 'bg-warning';
            }
        }
    }

    return (
        <Calendar
            defaultView='month'
            minDetail='decade'
            prev2Label={null}
            next2Label={null}
            onChange={props.onChange}
            tileClassName={tileClassName}
            minDate={minDate}
        />
    )

}

export default WrappedCalendar;