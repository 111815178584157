export function setEventUser(eventId, user) {
    let watdayObj = getWatdayObj();
    let event;
    if (watdayObj) {
        event = findEvent(watdayObj, eventId);
        if (event) {
            event.user = user;
        } else {
            watdayObj.events.push({ eventId, user })
        }
    } else {
        watdayObj = { events: [{ eventId, user }] };
    }

    watdayObj.lastUserName = user.name;

    localStorage.setItem(buildEventStorageKey(), JSON.stringify(watdayObj));
}

export function getEventUser(eventId) {
    const watdayObj = getWatdayObj();
    if (watdayObj && findEvent(watdayObj, eventId)) {
        return findEvent(watdayObj, eventId).user;
    }
}

export function getLastUserName() {
    const watdayObj = getWatdayObj();
    return watdayObj && watdayObj.lastUserName;
}

function getWatdayObj() {
    return JSON.parse(localStorage.getItem(buildEventStorageKey()));
}

function findEvent(watdayObj, eventId) {
    const eventCollection = watdayObj.events.filter(event => event.eventId === eventId);
    if (eventCollection.length) {
        return eventCollection[0];
    }
}

function buildEventStorageKey() {
    return `watday`;
}