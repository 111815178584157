import React, { useEffect, useState } from 'react'
import { getEvent } from '../services/EventService';
import { useParams, useNavigate } from 'react-router-dom'
import { notifyWarn } from '../redux/notificationSlice';
import { useDispatch } from "react-redux";


function EventView() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [event, setEvent] = useState(null);
    const { eventId } = useParams();
    const [loading, setLoading] = useState(true);
    const [activeDate, setActiveDate] = useState(null);

    const isActive = (date) => { return activeDate && date === activeDate }

    const activateDate = (date) => {
        if (!activeDate) {
            setActiveDate(date);
        } else {
            let newDate = activeDate === date ? null : date;
            setActiveDate(newDate);
        }
    }

    const getBadgeClass = (availableUsers) => {
        if (availableUsers.length === 1) {
            return 'danger';
        }
        if ((event.eventUsers.length - availableUsers.length) === 0) {
            return 'success';
        }
        return 'warning'
    }

    const share = async () => {
        try {
            await navigator.share({
                title: `WatDays - ${event.name}`,
                text: `${event.name} - I've created a new event using WatDays. Click the link to join in.`,
                url: `${process.env.REACT_APP_WATDAY_URL}/event/${event.eventId}/view`
            });
        } catch (err) {
            dispatch(notifyWarn('Something went wrong sharing event.'));
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const event = await getEvent(eventId);
            setEvent(event);
        }

        fetchData()
            .catch(console.error)
            .finally(() => setLoading(false));

    }, [eventId]);

    return (
        <div className="p-2">
            {
                loading &&
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            }


            {
                !loading && event &&
                <div>
                    <div className="row">
                        <div className="col-8">
                            <h1 className="pb-2 overflow-auto">{event.name}</h1>
                        </div>
                        <div className="col-4">
                            <button type="button" className="btn btn-outline-primary float-end" onClick={() => share()}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-person-plus-fill mx-2" viewBox="0 0 16 16">
                                    <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                    <path d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z" />
                                </svg>
                                Invite
                            </button>
                        </div>
                    </div>
                    <p className="lead">{event.description}</p>
                    {event.availableDates && event.availableDates.length > 0 &&
                        <div className="d-grid gap-2 d-flex justify-content-end mb-4">
                            <button type="button" className="btn btn-primary" onClick={() => navigate(`/event/${eventId}/add`)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar me-2" viewBox="0 0 16 16">
                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                </svg>
                                Update My Availabillity
                            </button>
                        </div>
                    }
                    {(!event.availableDates || event.availableDates.length === 0) &&
                        <div className="text-center pt-5">
                            <h2>No dates yet...</h2>
                            <div className="col-lg-6 mx-auto">
                                <p className="lead mb-4">Add some to get started.</p>
                                <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                                    <button type="button" className="btn btn-primary" onClick={() => navigate(`/event/${eventId}/add`)}>Add First Dates +</button>
                                </div>
                            </div>
                        </div>
                    }
                    {event.availableDates && event.availableDates.length > 0 &&
                        <div className="accordion accordion-flush pb-3">
                            {event.availableDates.map((availableDate, index) => {
                                return (
                                    <div key={availableDate.date} className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingOne">
                                            <button
                                                onClick={() => activateDate(availableDate.date)}
                                                className={`accordion-button ${isActive(availableDate.date) ? '' : 'collapsed'}`}
                                                type="button">
                                                <span className="w-75">
                                                    {new Date(availableDate.date).toDateString()}
                                                    {
                                                        index === 0 &&
                                                        <span className="ps-2 text-warning">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                            </svg>
                                                        </span>
                                                    }
                                                </span>
                                                <span className={`badge rounded-pill text-bg-${getBadgeClass(availableDate.users)}`}>{availableDate.users.length}/{event.eventUsers.length}</span>
                                            </button>
                                        </h2>
                                        <div className={`accordion-collapse collapse ${isActive(availableDate.date) ? 'show' : ''}`}>
                                            <div className="accordion-body">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <ul className="list-group list-group-flush">
                                                            <li className="list-group-item list-group-item-success">Available</li>
                                                            {
                                                                availableDate.users.map((user) => {
                                                                    return (
                                                                        <li key={user.eventUserId} className="list-group-item">
                                                                            {user.name}
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div className="col-6">
                                                        <ul className="list-group list-group-flush">
                                                            <li className="list-group-item list-group-item-danger">Unavailable</li>
                                                            {
                                                                event.eventUsers
                                                                    .filter(eventUser => {
                                                                        const userAvailable = availableDate.users
                                                                            .some(availableUser => eventUser.eventUserId === availableUser.eventUserId)
                                                                        return !userAvailable;
                                                                    }
                                                                    )
                                                                    .map((user) => {
                                                                        return (
                                                                            <li key={user.eventUserId} className="list-group-item">
                                                                                {user.name}
                                                                            </li>
                                                                        )
                                                                    })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            )}

                        </div>
                    }


                </div>
            }
        </div>
    );
}

export default EventView;