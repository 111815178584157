import { notifyError } from '../redux/notificationSlice';
import store from '../redux/store';
import { doc, setDoc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { db, analytics } from './Firebase'
import { logEvent } from "firebase/analytics";
import short from 'short-uuid';


async function safePerform(action) {
    try {
        return await action();
    } catch (e) {
        if (process.env.NODE_ENV === 'development') {
            console.log(e)
        }

        try {
            if (e.message) {
                logEvent(analytics, 'app_error', { message: e.message });
            }
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.log(e)
            }
        }

        store.dispatch(notifyError("Oops. Something went wrong."));
    }
}

export async function createEvent(event) {
    return await safePerform(async () => {
        const eventId = short.generate();
        const newEvent = {
            name: event.name,
            description: event.description,
            eventUsers: []
        };

        await setDoc(doc(db, "events", eventId), newEvent);

        return { eventId, ...newEvent };
    }
    );
}

export async function getEvent(eventId) {
    return await safePerform(async () => {
        const docRef = doc(db, "events", eventId);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
            throw new Error(`Event not found. Event: ${eventId}`);
        }

        const data = docSnap.data();

        data.eventUsers.forEach(eventUser => {
            eventUser.availabillity = eventUser.availabillity.map(date => new Date(date.seconds * 1000)).filter(date => date.getTime() >= getDateWithoutTime(new Date()));
        });
        const availableDates = [];

        data.eventUsers.forEach(eventUser => {
            eventUser.availabillity.forEach(availableDate => {
                const matchedDates = availableDates.filter(mappedAvailableDate => mappedAvailableDate.date.getTime() === availableDate.getTime());
                if (matchedDates.length > 0) {
                    matchedDates[0].users.push(eventUser);
                } else {
                    availableDates.push({ date: availableDate, users: [eventUser] });
                }
            });
        });

        const sortedAvailableDates = availableDates.sort((a, b) => {
            const userSort = b.users.length - a.users.length;
            if (userSort === 0) {
                return new Date(a.date) - new Date(b.date);
            } else {
                return userSort;
            }
        });

        const mappedEvent = { eventId, ...data, availableDates: sortedAvailableDates };

        return mappedEvent;
    });
}

export async function addAvailabillity(eventId, user, availabillity) {
    return await safePerform(async () => {
        const docRef = doc(db, "events", eventId);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
            throw new Error(`Event not found. Event: ${eventId}`);
        }

        const data = docSnap.data();

        let updated = false;

        data.eventUsers.forEach(eventUser => {
            if (eventUser.eventUserId === user.eventUserId) {
                eventUser.availabillity = availabillity.dates;
                updated = true;
            }
        });

        if (!updated) {
            throw new Error(`User not found. Event: ${eventId}, User: ${user.eventUserId}`);
        }

        await updateDoc(docRef, {
            eventUsers: data.eventUsers
        });

        return availabillity;
    });
}

export async function submitEventUser(eventId, name) {
    return await safePerform(async () => {
        const docRef = doc(db, "events", eventId);

        const newEventUser = { eventUserId: short.generate(), name, availabillity: [] }

        await updateDoc(docRef, {
            eventUsers: arrayUnion(newEventUser)
        });

        return newEventUser;
    });
}

export async function getEventUser(eventId, eventUserId) {
    const event = await getEvent(eventId)

    if (!event) {
        return;
    }

    const filteredData = event.eventUsers.filter(eventUser => eventUser.eventUserId === eventUserId);

    if (!filteredData.length) {
        return;
    }
    return filteredData[0];
}

export async function findEventUserByName(eventId, name) {
    const event = await getEvent(eventId)

    if (!event) {
        return;
    }

    const filteredData = event.eventUsers.filter(eventUser => eventUser.name.toLowerCase() === name.toLowerCase());

    if (!filteredData.length) {
        return;
    }
    return filteredData[0];
}

function getDateWithoutTime(date) {
    return date.setHours(0,0,0,0);
 }