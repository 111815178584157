import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div className="alert alert-danger" role="alert">
      Oops. Something went wrong. Try refreshing your page.
    </div>;
    }

    return this.props.children; 
  }
}
