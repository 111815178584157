import EventView from './pages/EventView'
import EventCreate from './pages/EventCreate'
import Home from './pages/Home'
import EventAddDates from './pages/EventAddDates'
import ErrorBoundary from './components/ErrorBoundary'
import Notification from './components/Notification'
import {
  Route,
  Routes
} from "react-router-dom";

function App() {
  return (
    <div>
      <header className="p-3 text-bg-dark">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
            <a href="/" className="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none">
              <img src="/logo192.png" alt="" width="50" height="50" />
            </a>
            <h1 className="ps-2">WatDays</h1>
          </div>
        </div>
      </header>
      <div className="px-4 pt-4">
        <ErrorBoundary>
          <Routes>
            <Route path='/event/:eventId/view' element={<EventView />} />
            <Route path='/event/:eventId/add' element={<EventAddDates />} />
            <Route path='/event' element={<EventCreate />} />
            <Route path='/' element={<Home />} />
          </Routes>
          <Notification />
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default App;
