import { useState } from 'react';
import { createEvent } from '../services/EventService'
import { useNavigate } from 'react-router-dom'
import { useBeforeunload } from 'react-beforeunload';
import { notifySuccess } from '../redux/notificationSlice';
import store from '../redux/store';

function EventCreate() {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [event, setEvent] = useState(null);
    const [copied, setCopied] = useState(false);
    const [wasValidated, setWasValidated] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const navigate = useNavigate();

    useBeforeunload(() => {
        if (event && !copied) {
            return "You won't be able to return to the event without copying your link. Are you sure you want to leave?"
        }
    });

    const submit = async () => {
        if (!name || name.length === 0 || !description || description.length === 0) {
            setWasValidated(true);
        } else {
            setSubmitting(true);
            try {
                const newEvent = await createEvent({ name, description })
                setEvent(newEvent);
            } finally {
                setSubmitting(false);
            }
        }
    }
    const navigateToEvent = () => {
        if (copied) {
            navigate(`/event/${event.eventId}/view`);
        } else {
            if (window.confirm("You haven't copied your unique URL. You'll need this to get back to the event later and to share with others. Are you sure you want to continue?")) {
                navigate(`/event/${event.eventId}/view`);
            }
        }
    }

    const copyToClipBoard = () => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_WATDAY_URL}/event/${event.eventId}/view`);
        setCopied(true);
        store.dispatch(notifySuccess("Copied to clipboard."));
    }

    return (
        <div className="p-2">
            <h1 className="pb-2">Create your Event</h1>
            {!event &&
                <div>
                    <form className={wasValidated ? 'was-validated' : ''} >
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Display Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                autoComplete="off"
                                value={name}
                                onChange={e => setName(e.target.value)}
                                required
                                maxLength={50}
                            />
                            <div className="invalid-feedback">
                                Please enter a display name for the event.
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="description" className="form-label">Description</label>
                            <textarea
                                className="form-control"
                                id="description"
                                autoComplete="off"
                                value={description}
                                maxLength={140}
                                required
                                onChange={e => setDescription(e.target.value)} />
                            <div className="form-text">{((description && description.length) || 0)} of 140 characters</div>
                            <div className="invalid-feedback">
                                Please enter a short description of the event.
                            </div>
                        </div>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={submit}
                                disabled={submitting}>
                                Submit {submitting && <span className="spinner-border spinner-border-sm"></span>}
                            </button>
                        </div>
                    </form>
                </div>
            }

            {event &&
                <div>
                    <div className="card text-bg-success mb-3">
                        <div className="card-body">
                            <h5 className="card-title">Success!</h5>
                            <p className="card-text">You've created a new event - {event.name}</p>
                            <p className="card-text">Here's a unique link you can use to access and share your event.</p>
                            <div className="input-group mb-3" onClick={copyToClipBoard}>
                                <input
                                    type="text"
                                    className="form-control font-monospace"
                                    value={`${process.env.REACT_APP_WATDAY_URL}/event/${event.eventId}/view`}
                                    disabled={true}
                                />
                                <button
                                    className="btn btn-outline-light"
                                    type="button"
                                >
                                    {!copied &&
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" className="bi bi-clipboard" viewBox="0 0 16 16">
                                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                        </svg>
                                    }
                                    {copied &&
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                        </svg>
                                    }
                                </button>
                            </div>
                            <div className="alert alert-warning" role="alert">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill mx-2" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                </svg>
                                It's <span className="fw-bold">important</span> to save this link to get back to your event.
                            </div>

                            <div className='text-end'>
                                <button type="button" className="btn btn-link text-light" onClick={() => navigateToEvent()}>Go to event</button>
                            </div>
                        </div>
                    </div>
                </div>

            }
        </div>
    )
}

export default EventCreate;