import { useNavigate } from "react-router-dom"
function Home() {
    const navigate = useNavigate();

    const createNew = () => {
        navigate('/event')
    }

    return (

        <div className="text-center">
            <img className="d-block mx-auto mb-4" src="/logo192.png" alt="" width="72" height="72" />
            <h1 className="display-5 fw-bold">WatDays</h1>
            <div className="col-lg-6 mx-auto">
                <p className="lead mb-4">
                    Quickly create and share events to schedule with friends.
                    No log in required, no hassle...
                </p>
                <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                    <button onClick={createNew} type="button" className="btn btn-primary btn-success">Create New +</button>
                </div>
            </div>
        </div>



    )
}

export default Home;