import { createSlice } from '@reduxjs/toolkit'

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    value: null,
  },
  reducers: {
    notifyError: (state, action) => {
      state.value = { state: 'danger', message: action.payload };
    },
    notifySuccess: (state, action) => {
      state.value = { state: 'success', message: action.payload };
    },
    notifyWarn: (state, action) => {
      state.value = { state: 'warning', message: action.payload };
    },
    clear: (state) => {
      state.value = null;
    },
  },
})

export const { notifyError, notifySuccess, notifyWarn, clear } = notificationSlice.actions

export default notificationSlice.reducer