import React, { useEffect, useState } from 'react';
import WrappedCalendar from '../components/WrappedCalendar';
import * as EventService from '../services/EventService';
import { useParams, useNavigate } from 'react-router-dom';
import * as StorageService from '../services/StorageService';



function EventAddDates() {
    const [eventUser, setUser] = useState(null);
    const [userName, setUserName] = useState(StorageService.getLastUserName() || '');
    const [userNameErrorMessage, setUserNameErrorMessage] = useState(null);
    const [event, setEvent] = useState(null);
    const [selectedDates, setSelectedDates] = useState([]);
    const [availableDates, setAvailableDates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const { eventId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const promises = [];
        const userObj = StorageService.getEventUser(eventId);

        if (userObj) {
            setUser(
                userObj
            );
            promises.push(
                (async () => {
                    setSelectedDates((await EventService.getEventUser(eventId, userObj.eventUserId)).availabillity);
                }
                )());
        }

        promises.push((async () => setEvent(await EventService.getEvent(eventId)))());

        Promise.all(promises)
            .catch(console.error)
            .finally(() => setLoading(false));

    }, [eventId]);

    useEffect(() => {
        if (!eventUser) {
            return;
        }

        setLoading(true);

        const fetchData = async () => {
            setAvailableDates(
                (await EventService.getEvent(eventId))
                    .availableDates
                    .filter(availableDates => !availableDates.users.some(user => user.eventUserId === eventUser.eventUserId))
                    .map(availableDate => availableDate.date)
            );
        }

        fetchData()
            .catch(console.error)
            .finally(() => setLoading(false));
    }, [eventId, eventUser]);

    const submit = async () => {
        setSubmitting(true);
        try {
            await EventService.addAvailabillity(eventId, eventUser, { dates: selectedDates });
        } finally {
            setSubmitting(false);
        }
        navigate(`/event/${eventId}/view`);
    }

    const onChange = (clickedDate) => {
        if (selectedDates.filter(selectedDate => selectedDate.getTime() === clickedDate.getTime()).length === 1) {
            setSelectedDates(selectedDates.filter(item => item.getTime() !== clickedDate.getTime()));
        } else {
            setSelectedDates([...selectedDates, clickedDate]);
        }
    }

    const submitUser = async () => {
        if (!userName || userName.length === 0) {
            setUserNameErrorMessage('Please enter a short user name');
            return;
        }

        setSubmitting(true);
        try {
            const existingUser = await EventService.findEventUserByName(eventId, userName);
            if (existingUser) {
                setUserNameErrorMessage('This user name is already taken for this event');
                return;
            }

            const response = await EventService.submitEventUser(eventId, userName);

            setUserNameErrorMessage(null);
            StorageService.setEventUser(eventId, { name: response.name, eventUserId: response.eventUserId });
            setUser(response);
            setLoading(true);

        } finally {
            setSubmitting(false);
        }
    }

    return (
        <div className="p-2">
            {!loading && !eventUser &&
                <div>
                    <form>
                        <div className="mb-3">
                            <label htmlFor="userName" className="form-label">User Name</label>
                            <input
                                type="text"
                                className={`form-control ${userNameErrorMessage ? 'is-invalid' : ''}`}
                                id="userName"
                                autoComplete="off"
                                aria-describedby="nameHelp"
                                value={userName}
                                onChange={e => setUserName(e.target.value)}
                                required
                                maxLength="20"
                            />
                            <div className="invalid-feedback">
                                {userNameErrorMessage}
                            </div>
                            <div id="nameHelp" className="form-text">This will be used as your unique display name within the event.</div>
                        </div>
                    </form>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <button
                            type="button"
                            className="mt-2 mx-2 btn btn-primary"
                            onClick={submitUser}
                            disabled={submitting}
                        >
                            Submit {submitting && <span className="spinner-border spinner-border-sm"></span>}
                        </button>
                    </div>
                </div>
            }

            {!loading && eventUser && event &&
                <div>
                    <h1 className="pb-2">Add Dates</h1>
                    <div className="px-2  calendar-container col-md-4 mx-auto pb-3">
                        <WrappedCalendar onChange={onChange} selectedDates={selectedDates} availableDates={availableDates} />
                    </div>
                    <div className='col-md-4 mx-auto'>
                        <div>
                            <span className="badge rounded-pill text-bg-success p-1"> </span> Your Selected Dates
                        </div>
                        <div>
                            <span className="badge rounded-pill text-bg-warning p-1"> </span> Dates Selected By Others
                        </div>
                    </div>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <button
                            type="button"
                            className="mt-2 mx-2 btn btn-primary"
                            onClick={submit}
                            disabled={submitting}>
                            Submit {submitting && <span className="spinner-border spinner-border-sm"></span>}
                        </button>
                    </div>
                </div>
            }

            {loading &&
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            }
        </div>
    );
}

export default EventAddDates;