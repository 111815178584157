// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyBi9FjAJ_83si8acZM1hEUxknb3JTyui2w",
  authDomain: "watday-fcb4b.firebaseapp.com",
  projectId: "watday-fcb4b",
  storageBucket: "watday-fcb4b.appspot.com",
  messagingSenderId: "734456451084",
  appId: "1:734456451084:web:0fe1f2101af4d6e6d8de29",
  measurementId: "G-9FE8X1Q4LP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


export const db = getFirestore(app);
export const analytics = getAnalytics(app);
